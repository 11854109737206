/* prettier-ignore */
.PCBuild {
   width: 100%;
   height: 508PX;
   padding: 50PX 0;
   background: linear-gradient(180deg, #FFF 0%, #F1F4F9 100%);
   .buildIcon {
       width: 500PX;
       height: 350PX;
   }
   .buildTips {
       margin-top: 35PX;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14PX;
   }
}
