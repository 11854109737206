.mobileFooter {
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  .upper {
    padding: 34px 0 28px 18px;
  }
  .collapseWrap {
    padding: 0 16px;
    .container {
      color: rgba(255, 255, 255, 0.85);
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      .title {
        @apply flex justify-between items-center;
      }
      .typeList {
        @apply flex flex-col;
        padding-bottom: 4px;
        .typeItem {
          @apply flex items-center;
          margin-bottom: 16px;
          color: rgba(255, 255, 255, 0.85);
          font-size: 16px;
          font-weight: 400;
        }
        .accountCodeWrap {
          width: 200px;
        }
        .img {
          width: 100%;
          height: 200px;
          margin: 8px 0 6px;
        }
        .accountText {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
  .mobileBottom {
    padding: 40px 0 24px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    .copyrightWrap {
      @apply flex flex-col;
      line-height: 24px;
    }
    .link {
      color: rgba(255, 255, 255, 0.5);
    }
    .pliceIcon {
      align-self: center;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      background: url(../../../../assets/CN/images/police.png) center center / contain no-repeat;
    }
    .agreement {
      justify-content: space-between;
      padding: 0 31px;
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 10px;
    }
    .privacyPolicy {
      margin: 0 12px;
    }
  }
}
