@import "@src/styles/mixins.less";

.international-main {
  @font-face {
    font-family: "Inter";
    src: url("../../assets/font/Inter-formal.ttf") format("truetype");
    /* 使用 swap 模式，为字体提供一个非常小的阻塞周期。 */
    font-display: swap;
  }
  font-family: Inter;
}

.PCPageContentWrap {
  display: flex;
  flex-direction: column;
  min-width: 1440px;
}

.MobilePageContentWrap {
  min-width: 750px;
}

.PCHeader {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.PCookies {
  position: fixed;
  bottom: 10px;
  padding: 36px 72px;
  border: 1px solid #b8b4b4;
  border-radius: 15px;
  background-color: rgb(248, 247, 253);
  width: 35%;

  .PCTextContent {
    color: "#000";
    font-weight: normal;
    font-size: 14px;
    .PCPrivateLink {
      display: inline-block;
      padding: 1px;
      border-bottom: 1px solid #000;
    }
  }

  .PCCookieButton {
    padding: 16px;
    width: 100%;
    .ant-btn.ant-btn-primary {
      color: #fff;
      border-radius: 12px;
      padding: 14px 32px;
      height: 52px;
      background-color: #4340f4;
      font-size: 16px;
      line-height: 24px;

      &:last-child {
        background-color: #ffffff;
        outline: 1px solid #4340f4;
        color: #696974;
        margin-left: 16px;
      }
    }
  }
}

.PCMobileCookies {
  position: fixed;
  bottom: 10px;
  padding: 10px;
  border: 1px solid #b8b4b4;
  border-radius: 15px;
  background-color: rgb(248, 247, 253);
  width: 80%;

  .PCTextContent {
    color: "#000";
    font-weight: normal;
    font-size: 14px;
    .PCPrivateLink {
      display: inline-block;
      padding: 1px;
      border-bottom: 1px solid #000;
    }
  }

  .PCCookieButton {
    padding: 10px;
    width: 100%;
    .ant-btn.ant-btn-primary {
      color: #fff;
      border-radius: 12px;
      padding: 10px;
      height: 52px;
      background-color: #4340f4;
      font-size: 16px;
      align-content: center;

      &:last-child {
        background-color: #ffffff;
        outline: 1px solid #4340f4;
        color: #696974;
        margin-left: 16px;
      }
    }
  }
}
