/* prettier-ignore */
.headerWrap {
    width: 100%;
    min-width: 1400PX;
    font-size: 14PX;
    height: 60PX;
    .header {
      width: 1200PX;
      height: 60PX;
      .logo {
        width: 87PX;
        height: 24PX;
        background: url("../../../../assets/CN/images/logo-resto.png") center center/ contain no-repeat;
      }

    .languageLabel {
      margin-left: 4PX;
      font-size: 14PX;
    }

    .switchLanguage {
      width: 220PX;
      height: 260PX;
      padding:4PX 0;
      overflow-y: scroll;
      border-radius: 2PX;
      background: #fff;
      box-shadow: 0PX 3PX 6PX -4PX rgba(0, 0, 0, 0.12), 0PX 6PX 16PX 0PX rgba(0, 0, 0, 0.08),
          0PX 9PX 28PX 8PX rgba(0, 0, 0, 0.05);

      right: 0PX;
      top: 60PX;
      z-index: 9;
      font-size: 14PX;

      .languageItem {
        padding: 5PX 12PX;
        &:hover {
          background: rgba(67, 64, 244, 0.08);
        }
      }
    }

      .contact {
        height: 32PX;
        padding: 0 16PX;
        border-radius: 32PX;
        background: #4340f4;
      }
      .PCNavList {
        .tabTitle {
          padding: 0 22PX;
          &:hover {
            color: #3e3bf8;
            font-weight: 500;
          }
        }
        .activeTab {
          color: #3e3bf8;
          font-weight: 500;
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0PX;
            right: auto;
            height: 2PX;
            width: 26PX;
            transform: translateX(-50%);
            background-color: #3e3bf8;
          }
        }
        .chevronDown {
          transform: rotate(0deg);
          transition: transform 0.3s linear;
        }
        .chevronUp {
          transform: rotate(180deg);
          transition: transform 0.3s linear;
        }
        .navWrap {
          position: absolute;
          z-index: 999;
          top: 60PX;
          padding: 32PX;
          border-radius: 0PX 0PX 12PX 12PX;
          background: var(--White, #fff);
          box-shadow: 0PX 4PX 40PX 0PX rgba(0, 0, 0, 0.1);
        }
        .navWrap:hover + .tabTitle {
          color: #3e3bf8;
        }
        .navContent {
          // width: 140PX;
          font-size: 14PX;
          margin-bottom: 16PX;
          white-space: nowrap;
          margin-right: 30px;
          &:last-of-type {
            margin-bottom: 0;
            margin-right: 0;
          }
        }
        .navTitle {
          color: rgba(0, 0, 0, 0.45);
        }
        .navBlackTitle {
          color: rgba(0, 0, 0, 0.85);
        }
        .navLabel {
          margin-top: 12PX;
        }
       
        .navLabel:first-of-type {
          margin-top: 16PX;
        }
        .navTitle:hover,
        .navLabel:hover {
          color: #3e3bf8;
        }
        .activeNavOrLabel {
          color: #3e3bf8;
        }
      }
    }
  
}
