@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  line-height: 1.5;
  font-weight: 400;
  color: #111827;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  display: flex;
  width: 100%;
  place-items: center;
  // min-height: 100vh;
}

#root {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  // height: 100vh;
}
