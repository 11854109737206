@import "@src/styles/mixins.less";

.PCFooterWrap {
  background-color: #263238;
  height: 411px;
  color: #ffffff;
  font-size: 14px;
  padding: 44px 166px;

  .PCFooterContent {
    p {
      margin: initial;
    }

    .PCColumn1ForCompany {
      .PCFooterLogo {
        width: 222px;
        height: 40px;
        margin-bottom: 40px;
      }
      .PCIconLink {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        column-gap: 20px;
        img {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }
    }

    .PCColumn2ForMenu {
      width: 400px;
      margin-left: 160px;

      h1 {
        font-size: 18px;
      }

      .PCMenuGrid:extend(.PCGrid2Columns) {
        column-gap: 32px;
        row-gap: 12px;
        label {
          cursor: pointer;
        }
      }
    }

    .PCColumn2ForResource {
      h1 {
        font-size: 18px;
      }
      .PCMenu {
        display: flex;
        flex-direction: column;
        column-gap: 32px;
        row-gap: 12px;
        label {
          cursor: pointer;
        }
      }
    }

    .PCColumn4ForLegal {
      width: 200px;
      margin-left: 160px;

      h1 {
        font-size: 18px;
      }

      .PCMenu {
        display: flex;
        flex-direction: column;
        column-gap: 32px;
        row-gap: 12px;
        label {
          cursor: pointer;
        }
      }
    }
  }
}
