@import "@src/styles/mixins.less";

@media screen and (min-width: 1901px) {
  .PCHeaderWrap {
    min-width: 1440px;
    .PCHeader {
      width: @PCContainerwidth76;
      .PCLogoUnionNavListContainer {
        gap: 50px;
      }
    }
  }
}

@media screen and (max-width: 1900px) {
  .PCHeader {
    width: @PCContainerwidth76;
  }
}

.PCHeaderWrap {
  width: 100%;
  font-size: 16px;
  height: 60px;
  background: #f5f7fa;
  color: #18191f;
  .PCHeader {
    height: 60px;

    .PCNowStart {
      width: 150px;
      height: 52px;
      margin: auto 0;
      color: #ffffff;
      font-size: 16px;
      line-height: 24px;
      background-color: #4340f4;
      text-align: center;
      align-content: center;
      border-radius: 12px;
      margin-left: 20px;
      &:hover {
        background-color: #ffffff;
        color: #4340f4;
        outline: 1px solid #4340f4;
      }
    }

    .PCNavList {
      padding-left: 60px;
      .PCTabTitle {
        padding: 20px;
      }

      .PCNavWrap {
        position: absolute;
        z-index: 999;
        top: 60px;
        padding: 20px;
        border-radius: 13px;
        background: #706efe;
        box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.1);
        color: #fff;
        column-gap: 20px;

        .PCNavTitleDisabled {
          color: rgba(255, 255, 255, 0.34);
        }
      }
      .PCNavContent {
        width: 200px;
        line-height: 33px;
      }

      .PCNavSubContentTitleIcon {
        margin-right: 8px;
      }

      .PCNavTitle:not(.PCHasTitleIcon) {
        margin-left: 28px;
      }
    }

    .PCLanguageLabel {
      margin-left: 4px;
      font-size: 14px;
    }
    .PCSwitchLanguage {
      width: 220px;
      height: 300px;
      overflow-y: scroll;
      border: 1px solid #c9b8ff;
      border-radius: 12px;
      background: #fff;

      right: 0px;
      top: 60px;
      z-index: 9;

      color: #696974;
      font-size: 14px;

      .PCLanguageItem {
        padding: 7px 18px;
        &:hover {
          //   background: rgba(67, 64, 244, 0.08);
          background: #b1b0fa;
          color: #fff;
        }
      }
    }
  }
}
