.PCButton {
  position: relative;
  text-align: center;
  label {
    display: inline-block;
    padding: 14px 32px;
    font-size: 16px;
    font-weight: 500;
    color: var(--hover-before-color, #fff);
    line-height: 24px;
    background: var(--hover-before-background, #4340f4);
    border-radius: 12px;
    text-align: center;
    outline: none;
    cursor: pointer !important;
    .svgIcon {
      position: relative;
      bottom: -3px;
      right: -5%;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: var(--hover-after-background, #fff);
      color: var(--hover-after-color, #4340f4);
      // 使用outline 视觉改变 不会影响布局
      // border: 1px solid #4340f4;
      outline: 1px solid #4340f4;
      .svgIcon {
        transition: fill 0.3s ease;
        path {
          stroke: var(--hover-svg-color, #fff);
        }
      }
    }
  }
}

.PCLinkButton {
  position: relative;
  label {
    display: inline-block;
    color: #4340f4;
    line-height: 24px;
    text-align: center;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    .svgIcon {
      position: relative;
      bottom: -6px;
      right: -5%;
    }
  }
}
