/* prettier-ignore */
.PCFooterWrap {
    width: 100%;
    min-width: 1440PX;
    background: rgba(0, 0, 0, 0.85);
    .footerContent {
        padding: 60PX 0 80PX;
        width: 1200PX;
        margin: auto;
    }
    .experienceWrap {
        width: 274PX;
        .experienceBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160PX;
            height: 46PX;
            margin-top: 24PX;
            border-radius: 229PX;
            background: #3E3BF8;
            color: #ffffff;
            text-align: center;
            font-size: 14PX;
            font-weight: 500;
        }
    }
    .footerRight {
        font-size: 14PX;
        .typeTitle {
            color: rgba(255, 255, 255, 0.60);
            margin-bottom: 24PX;
        }
        .typeItem {
            color: rgba(255, 255, 255, 0.85);
            line-height: 26PX;
            .iconWrap {
                margin-right: 10PX;
            }
        }
        .accountCodeWrap {
            color: rgba(255, 255, 255, 0.70);
            width: 140px;
            margin-top: 24px;
            .img {
                width: 140px;
                height: 140px;
            }
            .accountText {
                text-align: center;
                margin-top: 6px;
                font-size: 12px;
            }
        }
    }
    .divider {
        width: 100%;
        height: 1PX;
        background: rgba(255, 255, 255, 0.15);
    } 
    .footerBottom {
        width: 1200px;
        margin: auto;
        // padding: 0 120PX;
        height: 56PX;
        line-height: 56PX;
        .link {
            color: rgba(255, 255, 255, 0.60);
        }
        .copyright {
            color: rgba(255, 255, 255, 0.60);
            font-size: 12PX;
        }
        .pliceIcon {
            align-self: center;
            width: 16PX;
            height: 16PX;
            margin-right: 4PX;
            background: url(../../../../assets/CN/images/police.png)  center center /contain no-repeat;
        }
        .agreement {
            width: 400px;
            flex: none;
            color: rgba(255, 255, 255, 0.60);
            justify-content: flex-end;
            font-size: 12PX;
        }
    }  
}
