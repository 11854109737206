/* prettier-ignore */
.PCConcatModal {
    .ant-input, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 4PX 0;
    }
    .ant-input-outlined,
    .ant-input-outlined:hover,
    .ant-input-outlined:focus,
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector  {
        border: none;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1PX solid rgba(0, 0, 0, 0.25);
    }
    .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
        border: none;
        border-bottom: 1PX solid #ff4d4f;
    }
    .addressLinkage {
        .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
        .ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector  {
            border: none;
            box-shadow: none;
            border-radius: 0;
            
        }
    }
    .title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24PX;
        font-weight: 500;
        text-align: center;
        margin-bottom: 40PX;
    }
    .radioOption {
        @apply flex items-center justify-between;
        cursor: pointer;
    }
    .btnWrap {
        @apply flex justify-between;
        padding: 20PX 50PX 20PX;
        .btn {
            @apply flex items-center justify-center flex-none;
            width: 200PX;
            height: 52PX;
            border-radius: 124PX;
            border: 1PX solid rgba(0, 0, 0, 0.25);
        }
        .submit {
           color: #ffffff;
            background: #5F5DF6;
            border: none;
        }
    }
   
}
// :where(.css-dev-only-do-not-override-1rqnfsa).ant-select-dropdown
//   .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   background: rgba(67, 64, 244, 0.08);
// }
.ant-modal .ant-modal-content {
  padding: 20px 40px;
}
