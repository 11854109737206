/* prettier-ignore */
.PCPageContentWrap {
  min-width: 1400PX;
  .ant-back-top {
    right: 100PX;
  }
  .ant-float-btn-default .ant-float-btn-body {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .anticon {
    color: #ffffff;
  }
}
.MobilePageContentWrap {
}
