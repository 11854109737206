@import "@src/styles/mixins.less";

.PCHeaderWrap {
  padding: 0 20px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  .PCHeader {
    height: 60px;
  }

  .PCMenuSection {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    background-color: rgba(25, 24, 48, 0.96);
    z-index: 1;
    padding: 24px 80px;

    font-size: 16px;
    line-height: 24px;

    .PCNavList {
      .PCTabTitle {
        margin: 10px 0;
      }

      .PCNavContent:not(.PCNavContentEnd) {
        margin: 10px 0;
      }

      .PCNavSubContentTitleIcon {
        margin-right: 10px;
      }

      .PCNavTitle:not(.PCHasTitleIcon) {
        padding: 5px;
      }

      .PCChevronDown {
        transform: rotate(0deg);
        transition: transform 0.3s linear;
      }
      .PCChevronUp {
        transform: rotate(180deg);
        transition: transform 0.3s linear;
      }
    }

    .PCNowStart {
      width: 100%;
      height: 52px;
      line-height: 52px;
      margin: auto 0;
      color: #ffffff;
      font-size: 16px;
      background-color: #4340f4;
      text-align: center;
      align-content: center;
      border-radius: 12px;
      margin-top: 36px;
      &:hover {
        background-color: #ffffff;
        color: #4340f4;
        outline: 1px solid #4340f4;
      }
    }

    .PCLanguageContainer {
      margin-top: 36px;
    }

    .PCLanguageLabel {
      margin-left: 10px;
      font-size: 14px;
    }
    .PCSwitchLanguage {
      width: 220px;
      height: 300px;
      overflow-y: scroll;
      border: 1px solid #c9b8ff;
      border-radius: 12px;
      background: #fff;

      color: #696974;
      font-size: 14px;

      .PCLanguageItem {
        padding: 7px 18px;
        &:hover {
          //   background: rgba(67, 64, 244, 0.08);
          background: #b1b0fa;
          color: #fff;
        }
      }
    }
  }
}
