// container宽度
@PCContainerwidth76: 75%;
@PCContainerMinwidth: 1440px;
@MobileContainerwidth80: "80%"; // 手机
.fadeinLeft {
  animation: fadeInFromLeft 1s ease-in-out;
  visibility: visible;
  animation-fill-mode: forwards;
}

.fadeinRight {
  animation: fadeInFromRight 1s ease-in-out;
  visibility: visible;
  animation-fill-mode: forwards;
}

// flex 垂直水平居中
.PCflexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
  word-break: keep-all;
}
// 两栏布局
.PCGrid2Columns {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 64px;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.media-style(@top, @right, @bottom, @left) {
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
}
